.Timetable {
    width: 100%;
    height: auto;
    padding-left: 2rem;
    margin-left: 1rem;
}

.Timetable .table {
    display: flex;
    flex: auto;
    flex-direction: row;
    height: 100%;
    width: 100%;

    overflow-y: scroll;
}

.Timetable .timestamps {
    display: flex;
    flex: 10%;
    flex-direction: column;

    min-height: calc(100% - 3rem);
    max-width: 3rem;
    margin-top: 5rem;
}

.Timetable .right {
    display: flex;
    flex: 90%;
    flex-direction: column;

    min-height: calc(100% - 3rem);
}

.Timetable .days {
    flex: auto;
    display: flex;
    flex-direction: row;

    min-height: 5rem;
}

.Timetable .timestamps div {
    display: flex;
    min-height: 6rem;
    max-width: 5rem;

    text-align: right;
}

.Timetable .days div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Timetable .fields {
    position: relative;

    min-height: calc(12 * 6rem);
    min-width: auto;

    margin-left: 0.8em;

    border-radius: 0.5rem;
    border: 1px solid var(--select-hover);
    color: #fff !important;
}
