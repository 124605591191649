.Menu .courses {
    flex: content;

    max-height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    padding: 0;
}

.Menu button.btn.btn-common {
    margin-bottom: 1rem;
    margin-right: 1rem;
}
