::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.Schedule {
    flex: auto;
    display: flex;
    flex-direction: row;

    padding: 1rem;
    margin: 0;
    background: var(--bgi);
}
