button.SignIn {
    display: block;

    padding: 0;
    margin: 0;

    width: 100%;
    height: 4rem;

    font-size: 1.3rem;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
}

button.SignIn img {
    margin-top: 0.3rem;
    width: 2rem;

    border-radius: 1rem;
}

button.SignIn:active {
    outline: none;
    border: none;
}
