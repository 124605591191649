:root {
    --accent: #007aff;
    /* --accent: #ff6978; */
    --success: #4daa57;
    --error: #d9594c;
    --gold: #ffd700;
}

.light {
    --bgc: #fff;
    --bgi: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='775' height='93' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23e1e1e1' stroke-width='2.5' stroke-opacity='0.67'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
    --select: #fffef5;
    --select-hover: #e1e1e1;
    --text: #0f0f0f;
    --text-extra: #6e6e6e;
}

.dark {
    --bgc: #000;
    --bgi: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='775' height='93' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%231a1a1a' stroke-width='2.5' stroke-opacity='0.67'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
    --select: #0a0a0a;
    --select-hover: #1a1a1a;
    --text: #f6f6f8;
    --text-extra: #2c2c2e;
}

* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 13px;
}

body {
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1 {
    font-size: 2.5em;
    font-weight: 900;
}
h2 {
    font-size: 2em;
    font-weight: 900;
}
h3 {
    font-size: 1.7em;
    font-weight: 700;
}
h4 {
    font-size: 1.2em;
    font-weight: 700;
}
h5 {
    font-size: 1.1em;
    font-weight: 700;
}
p {
    font-size: 1em;
    font-weight: 400;
}
small {
    font-size: 0.8em;
    font-weight: 400;
}
code {
    font-size: 1em;
    font-weight: 400;
}

@font-face {
    font-family: "Phelix Boomgartner";
    src: url("./assets/PhelixBoomgartner.otf") format("opentype");
}

button.btn,
input.btn {
    width: 7rem;
    height: 3rem;

    outline: none;
    font-size: 1rem;
    font-weight: 600;
    background-color: var(--select);
    border: 1px solid var(--select-hover);
    border-radius: 0.5em;
    color: var(--text);
}

button.btn-confirm:hover,
input.btn-confirm:hover {
    border: 1px solid var(--success);
}

button.btn-confirm:active,
input.btn-confirm:active {
    background-color: var(--success);
}

button.btn-common:hover,
input.btn-common:hover {
    border: 1px solid var(--accent);
}

button.btn-common:active,
input.btn-common:active {
    background-color: var(--accent);
}

button.btn-error:hover,
input.btn-error:hover {
    border: 1px solid var(--error);
}

button.btn-error:active,
input.btn-error:active {
    background-color: var(--error);
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 20em;
    height: 3em;

    padding-left: 1em;
    margin: 0.5em 0;

    outline: none;
    font-size: 1rem;
    font-weight: 600;
    background-color: var(--select);
    border: 1px solid var(--select-hover);
    border-radius: 0.5em;
    color: var(--text);
}

input::placeholder {
    color: var(--text-extra);
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border: 1px solid var(--accent);
}

div.loader {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

div.loader div {
    align-self: center;
}

div.panel {
    padding: 1rem;

    border: 1px solid var(--select-hover);
    border-radius: 0.5rem;
    background-color: var(--select);
    color: var(--text);
}
