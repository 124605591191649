/* Theme change transition */
* {
    transition: border 200ms ease-in-out, background 200ms ease-in-out,
        color 200ms ease-in-out;
    -webkit-transition: border 200ms ease-in-out, background 200ms ease-in-out,
        color 200ms ease-in-out;
    -moz-transition: border 200ms ease-in-out, background 200ms ease-in-out,
        color 200ms ease-in-out;
    -o-transition: border 200ms ease-in-out, background 200ms ease-in-out,
        color 200ms ease-in-out;
}

button.ThemeSwitcher {
    padding: 0;
    margin: 0;

    width: 100%;
    height: 4rem;

    font-size: 1.3rem;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border: none;
    outline: none;
}

button.ThemeSwitcher:active {
    outline: none;
    border: none;
}
