.Selector {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;

    color: var(--text);
}

.Selector .chosen {
    height: 100%;
    flex-basis: calc(15rem + 1px);
    overflow-y: scroll;
}

.Selector .chosen button.btn.btn-confirm {
    margin-right: 1rem;
}

.Selector .chosen .chosen-courses {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    max-height: calc(100% - 3rem);

    margin-left: 0.1rem;
    margin-top: 1rem;
    overflow-y: scroll;
}

.Selector .search {
    flex: 1;
    margin-left: 1rem;
}

.Selector .search input {
    margin-right: 1rem;
}

.Selector .search .available-courses {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    max-height: calc(100% - 5rem);

    margin-left: 0.1rem;
    margin-top: 1rem;
    overflow-y: scroll;
}

.Selector .Course {
    flex: auto;
    margin-right: 0.8rem;
}

.Selector .Course button:hover {
    cursor: pointer;
}

.Selector .Course button:active {
    cursor: pointer;
    outline: none;
    border: none;
}
