.page-not-found {
    width: 100%;
    height: 100%;

    padding: 1rem;

    background-image: var(--bgi);
    color: var(--text);
}

.page-not-found h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    font-size: 8rem;
    color: var(--text-extra);
}

.page-not-found span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    font-size: 2rem;
}
